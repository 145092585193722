import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Layout from "../../components/layout"
import Footer from '../../components/footer';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Wrapper from '../../components/wrap';
import Dstitle from "../../images/wwgeg/ds_title.inline.svg"
import Icons from "../../images/wwgeg/icons.inline.svg"
import Bg from "../../images/wwgeg/bg.inline.svg"
import "../../slider.css"
import Slider from "react-slick"

const IndexPage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }
  return (
    <Layout navtype="light" title="Westwood Global Energy Group">
      <Head>
        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true" offset={0}>
          <Wrapper>
            <small>Westwood Global Energy Group</small>
            <h1>Design a consistent experience across five products</h1>
            <ul className="types">
              <li>
                <small>Timeframe</small>
                <br />
                Jan 2019–Jun 2021
              </li>
              <li>
                <small>Role</small>
                <br />
                Lead Product Designer
              </li>
              <li>
                <small>Responsibilities</small>
                <br />
                Research, UX design, Data Analysis, Usability Testing, Development hand-off, Design System Management and Documentation
              </li>
              <li className="full">
                <small>Team</small>
                <br />
                Company CTO, 2 Head of Departments, Lead Product Designer (me), 1 Senior UX Designer, 1 Front-End Developer, 3 Fullstack Engineers, 2 Project Managers, Client Service Manager, Data Analyst
              </li>
            </ul>
          </Wrapper>
        </AnimationOnScroll>
        <div className="bg">
          <AnimationOnScroll animateIn="animate__zoomIn" animateOnce="true" offset={0}>
            <Bg />
          </AnimationOnScroll>
        </div>
      </Head>
      <AnimationOnScroll animateIn="animate__wrapInUp" animateOnce="true">
      <Wrapper className='bottom-zero'>
        <p>
          <a href="https://www.westwoodenergy.com/" rel="noreferrer" target="_blank">Westwood Global Energy Group</a> is leading provider of research, analytics, and consulting services to the global energy industry, with offices in the UK, USA, Mexico, and Singapore. With a team of 70-200 employees, they generate over $17M in annual revenue and serve over 300,000 individual users
        </p>
        <strong className="sub-title">Challenges and goals</strong>
        <h3>Build an extensible, UX-friendly design/framework to support various products and data growth</h3>
        <ul>
          <strong>Business problems</strong>
          <br />
          The Westwood Global Energy Group has acquired several services
          <li>All services provide different user experiences</li>
          <li>All services are built on outdated or various tech stacks, leading to a decrease in development speed and hindering fast update deliverability</li>
          <li>The RigLogix app is built on an outdated tech stack</li>
          <li>The need to enable access to services using a single account, possibly as a package</li>
        </ul>

        <ul>
          <strong>User problems</strong>
          <li>Similar components provide different experience</li>
          <li>Difficulty in remembering the features of services</li>
          <li>Inconsistency in naming entities</li>
          <li>Different credentials for each service</li>
        </ul>

        <p>
          So, our main focus was to create a seamless user experience across all products and integrate products with each other.
          <br/>
          <a href="#results">View Results</a>
        </p>
        <br/>
        <strong className="sub-title">My role and the team</strong>
        <h3>The user-centered shift</h3>
        <p>I ran the whole design process, from planning to implementation. This included user research, designing the UX, UI, and the Design System. We were collaborating closely with the engineering and analytics teams and two Department Heads and CTO who looked after different areas. The team was located between London 🇬🇧, the United States 🇺🇸, and Mexico 🇲🇽.</p>

        <strong className="sub-title">Design Process</strong>
        <h3>Research</h3>
        <p>We need to build an extensible design system for five products and support growth of products functionality. The design system also helped us be much more efficient when designing and implementing UI.</p>

        <strong>What we already have</strong>
        <p>First, I looked at what already exists in the Riglogix, and other services.</p>
        <OldScreens>
          <Slider {...settings}>
            <div>
              <StaticImage
                placeholder="blurred"
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../../images/wwgeg/old_filters.png"
                alt="Old App filters"
              />
            </div>
            <div>
              <StaticImage
                placeholder="blurred"
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../../images/wwgeg/old_rigedge.png"
                alt="Old App Reports"
              />
            </div>
            <div>
              <StaticImage
                placeholder="blurred"
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../../images/wwgeg/old_dailylogic.png"
                alt="Old App Report"
              />
            </div>
            <div>
              <StaticImage
                placeholder="blurred"
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../../images/wwgeg/old_rigoutlook.png"
                alt="Old App Reports"
              />
            </div>
            <div>
              <StaticImage
                placeholder="blurred"
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../../images/wwgeg/old_faq.png"
                alt="Old App Reports"
              />
            </div>
          </Slider>
        </OldScreens>
        <p>After analyzing the features and limitations of the products, and to consolidate our knowledge, I gathered everything in one space with the team: including a glossary, user stories, scenarios, and the minimal set of controls we need to design.</p>
        </Wrapper>
        <div className="wide-scheme">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../images/wwgeg/map_knowledge.png"
            alt="Knowledge Map"
            className="img-with-theme-support"
          />
        </div>
        <Wrapper>
        <p>
          Also, I have collected user feedback through Client Service.
        </p>
        <p>
        With the development team, we decided to use the <a href="https://vuetifyjs.com/" rel="noreferrer" target="_blank">Vuetify</a> (UI Library on VueJS) to speed up the development process and hit the deadline.
        <br/>
        The easiest way to provide a similar experience across all products is to place logically similar tools in the same areas in different products. So, I have designed the universal wirefram of layout for all products.
        </p>
        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
          <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../images/wwgeg/layout_skeleton.png"
              alt="Layout Skeleton"
              className="img-with-theme-support"
            />
        </AnimationOnScroll>
        <p>We started with redesigning the current RigLogix application, because we had a list of requirements from the data analysis team. I started to design prototypes for the Riglogix product using Vuetify components. Also, we waited for the new brand guidelines from an external branding agency.</p>

        <p>
          <a target="_blank" rel="noreferrer" href="https://atomicdesign.bradfrost.com/">The Atomic design</a> fitted well with our structure, and it was clear to developers.
          We defined Vuetify components are atoms, and we configured atoms according to new brand guidelines. So, we built the draft of the Westwood Design System.
          It contains: Color coding for each product and charts, a set of atoms and molecules.
          </p>
      </Wrapper>
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true" className="ww-ds">
        <div className="ds-title">
        <AnimationOnScroll animateIn="animate__slideInUp" animateOnce="true" offset={0}>
          <Dstitle />
        </AnimationOnScroll>
        </div>
        <div className="ds-row">
          <div className="ds-col col-left">
            <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce="true">
              <StaticImage
                placeholder="blurred"
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../../images/wwgeg/ds_col.png"
                alt="Westwood Design System Preview"
              />
              <Icons className="icons" />
            </AnimationOnScroll>
          </div>
          <div className="ds-col col-right">
            <div className="ds-col nowrap">
              <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
                <StaticImage
                  placeholder="blurred"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  src="../../images/wwgeg/ds_col2.png"
                  alt="Westwood Design System Preview"
                />
              </AnimationOnScroll>
              <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
                <StaticImage
                  placeholder="blurred"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  src="../../images/wwgeg/ds_col3.png"
                  alt="Westwood Design System Preview"
                />
              </AnimationOnScroll>
              <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
                <StaticImage
                  placeholder="blurred"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  src="../../images/wwgeg/ds_col4.png"
                  alt="Westwood Design System Preview"
                />
              </AnimationOnScroll>
            </div>
            <AnimationOnScroll animateIn="animate__slideInUp" animateOnce="true" offset={0}>
              <StaticImage
                placeholder="blurred"
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../../images/wwgeg/ds_col5.png"
                alt="Westwood Design System Preview"
              />
            </AnimationOnScroll>
          </div>
        </div>
        <small>A small part of the design system I created for Westwood.</small>
      </AnimationOnScroll>
      <Wrapper>
        <br/>
        <p>
          Having all of the above, I redesigned all necessary user scenarios and created new templates for common views. So, I filled the Design System with organisms and templates for specific scenarios and cases.
        </p>
        <p>Following a user-centered approach, we ran user research throughout the process to understand our users and get feedback from them. Every week, the product team played back the Riglogix project to representants from each department. This way, everyone could be on the same page and key decisions could be made together.</p>
      </Wrapper>
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
        <div className="wide-scheme">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../images/wwgeg/Frames.png"
            alt="Build report flow"
          />
        </div>
      </AnimationOnScroll>
      <div className="screens-with-animations">
        <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce="true">
          <div className="screen">
            <div className="screen-bar"></div>
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../images/wwgeg/map.png"
              alt="map"
            />
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
          <div className="screen">
            <div className="screen-bar"></div>
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../images/wwgeg/comparision.png"
              alt="comparision"
            />
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce="true">
          <div className="screen">
            <div className="screen-bar"></div>
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../images/wwgeg/platformlogix.png"
              alt="platformlogix"
            />
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
          <div className="screen">
            <div className="screen-bar"></div>
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../images/wwgeg/windlogix.png"
              alt="windlogix"
              />
          </div>
        </AnimationOnScroll>

        <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce="true">
          <div className="screen">
            <div className="screen-bar"></div>
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../images/wwgeg/supply_demand.png"
              alt="supply and demand"
            />
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
          <div className="screen">
            <div className="screen-bar"></div>
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../images/wwgeg/capabilities.png"
              alt="capabilities"
            />
          </div>
        </AnimationOnScroll>
      </div>
      <Wrapper id="results" name="results">
        <strong className="sub-title">Outcomes</strong>
        <h3>A measurable impact</h3>
        <p>Further, following the rules of the design system (collected in the Storybook), I designed the new version of RigLogix App. Also, I took a part in designing SubseaLogix, PlatformLogix apps, as a result I filled all possible UX patterns to Design System. Later, another dev team used Westwood Design System to develop WindLogix, Global Land Rigs. They worked only with a data analysis team without adding anything to the design system.</p>
        <ul>
          <li>Reduced complaints related to confusion with the product up to 20%</li>
          <li>Shortered design and development time up to 30%</li>
        </ul>
        <h3>Learnings</h3>
        <p>The design system reacts to real user and product needs. You can’t just take an existing design system and apply it to your product.</p>
        <p>If there is no design system in the product, then Atomic Design is what is easiest to explain to development and what is easiest to start with.</p>
      </Wrapper>
      </AnimationOnScroll>
      <Footer />
    </Layout>
  )
}

const OldScreens = styled.div`
  margin-top: -20px;

  div {
    text-align: center;
  }
  
  .gatsby-image-wrapper {
    margin: 20px auto;
    box-shadow: 0 0 18px 0 rgba(0,0,0,.1);
    border-radius: 8px;
  }

  .slick-dots li {
    background: rgb(240, 239, 243);
    border-radius: 10px;
    border: solid 3px #fff;
  }

  .slick-dots .slick-active {
    background: rgb(99, 92, 227);
  }
`
const Head = styled.nav`
  position: relative;
  overflow: hidden;
  background: #f0eff3;
  padding-top: 3rem;
  padding-bottom: .5rem;
  margin-bottom: 3rem;

  h1 {
    margin: 5px 0 20px;
    max-width: 730px;
  }

  .bg {
    position: absolute;
    right: -210px;
    top: 0;
  }

  svg {
    width: 700px;
    height: 700px;
  }

  .dark & {
    background: #201E32;
  }

  @media (max-width: 1280px) {
    .bg {
      top: -100px;
      width: 500px;
    }
  }

  @media (max-width: 1024px) {
  }

  @media (max-width: 820px) {
    svg {
      width: 450px;
      height: 450px;
    }

    .types {
      font-size: var(--fontSize-2);
      line-height: 1.5;
    }

    .bg {
      right: -250px;
      opacity: .5;
    }
  }

  @media (max-width: 630px) {
    .types {
      display: block;
    }

    .bg {
      top: -250px;
    }
  }

  @media (max-width: 400px) {
    .bg {
      right: -250px;
    }
  }

  @media (max-width: 350px) {
  }
`
export default IndexPage
